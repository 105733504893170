import { mapActions, mapMutations } from "vuex";

export default {
    name:"SignIn",

    data () {
        return {
            valid: false,
            isLoading: false,
            email: '',
            emailRules: [
                v => !!this.validateRequired(v) || 'البريد الالكتروني مطلوب',
                v => this.validateEmailFormat(v) || 'يجب أن يكون البريد الإلكتروني بالتنسيق الصحيح',
            ],
            password: '',
            passwordRules: [
                v => !!this.validateRequired(v) || 'كلمة المرور مطلوبة',
            ]
        }
    },

    created() {
        this.activateUser()
    },

    methods: {
        ...mapActions('auth', ['activate', "forgot"]),
        ...mapActions('auth', ['loginFrontend']),
        ...mapMutations('notification', ['notify']),

        async activateUser() {
            if (this.$route.params.uuid) {
                try {
                    var uuid = {uuid: this.$route.params.uuid}
                    await this.activate(uuid)
                } catch {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            }
        },

        async signIn() {
            var user = {
                email: this.email,
                password: this.password
            }

            if (this.validateUser(user)) {
                this.isLoading = true
                await this.loginFrontend(user).then(
                    ({needsActivation, validAccount}) => {
                        if (needsActivation) {
                            this.notify({message: 'تم إرسال بريد إلكتروني يحتوي على رابط جديد لتفعيل حسابك من خلاله', show: true})
                        } else {
                            if(validAccount) {
                                this.$router.push({ name: 'home' })
                            } else {
                                this.notify({message: 'لم يتم العثور على مستخدم بالبريد الإلكتروني وكلمة المرور المحددين', show: true})
                            }
                        }
                    }, () => { 
                        this.notify({message: 'لم يتم العثور على مستخدم بالبريد الإلكتروني وكلمة المرور المحددين', show: true})
                    }
                )
                this.isLoading = false
            } else {
                this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
            }
        },

        validateRequired(value) {
            return value.length > 0
        },

        validateEmailFormat(email) {
            return /.+@.+/.test(email)
        },

        validateUser(user) {
            return this.validateRequired(user.email) &&
                this.validateRequired(user.password) &&
                this.validateEmailFormat(user.email)
        },

        async resetPassword() {
            this.isLoading = true;
            await this.forgot({email: this.email}).then(
                () => {
                    this.notify({message: 'يرجى التحقق من بريدك الإلكتروني للحصول على الرابط', show: true})
                }, () => { 
                    this.notify({message: 'لم يتم العثور على أي مستخدم مع البريد الإلكتروني المحدد', show: true})
                }
            )
            this.isLoading = false;
        }
    }
}