<template>
  <div class="forgot-password justify-center d-flex mt-15">
    <div
      class="forgot-password-card d-flex justify-content-center flex-column align-items-center"
    >
      <div class="custom-title mb-3">
        <div>تغيير كلمة المرور</div>
        <div class="horizontal-line"></div>
      </div>
      <v-container>
        <v-row class="mb-3 justify-center">
          <v-col cols="12" sm="10" md="10" class="px-4 ml-0 ml-md-5">
            <v-text-field
              v-model="password"
              :rules="passwordRules"
              type="password"
              label="كلمة المرور الجديدة"
              required
              validate-on-blur
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="10" md="10" class="px-4 ml-0 ml-md-5">
            <v-text-field
              v-model="confirmPassword"
              :rules="confirmPasswordRules"
              type="password"
              label="تأكيد كلمة السر"
              required
              validate-on-blur
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-progress-circular
        v-if="isLoading"
        class="mb-5"
        indeterminate
        color="grey lighten-2"
      >
      </v-progress-circular>
      <button
        v-else
        :disabled="isLoading"
        class="submit-button mb-5"
        @click="updatePassword"
      >
        حفظ
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "ForgotPassword",
  components: {},

  data() {
    return {
      valid: false,
      isLoading: false,
      password: "",
      confirmPassword: "",
      passwordRules: [
        (v) => !!this.validateRequired(v) || "كلمة المرور مطلوبة",
      ],
      confirmPasswordRules: [
        (v) => !!this.validateRequired(v) || "كلمة المرور مطلوبة",
        (v) => this.confirmPasswordValidation(v) || "كلمة السر غير متطابقة",
      ],
    };
  },
  methods: {
    ...mapActions("auth", ["reset"]),
    ...mapMutations("notification", ["notify"]),

    validateRequired(value) {
      return value.length > 0;
    },

    confirmPasswordValidation(confirmPassword) {
      return this.password === confirmPassword;
    },

    async updatePassword() {
      if (this.password === this.confirmPassword) {
        this.isLoading = true;
        await this.reset({
          uuid: this.$route.params.uuid,
          password: this.password,
        }).then(
          () => {
            this.$router.push({ name: "signin" });
          },
          () => {
            this.notify({
              message: "هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.",
              show: true,
            });
          }
        );
        this.isLoading = false;
      } else {
        this.notify({
          message: "كلمة السر غير متطابقة",
          show: true,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.forgot-password {
  .forgot-password-card {
    height: fit-content;
    min-width: 400px;
    max-width: 800px;
    background-color: #fff;
    box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.141);
    border-radius: 15px;
    padding: 20px;

    .custom-title {
      font-size: 45px;
      color: $gray-1;

      .horizontal-line {
        border-bottom: 2px solid $blue-1;
        width: 70px;
        border-radius: 10px;
      }
    }

    .submit-button {
      font-size: 24px;
      color: $blue-1;

      &:hover {
        color: $orange-main;
      }

      &:disabled {
        color: $gray-disabled;
        cursor: default;
      }
    }
  }
}
</style>
